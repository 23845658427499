import React from 'react'
import { graphql, Link } from 'gatsby'

import theme from '../../theme'
import Layout from '../../components/layout'
import Logo from '../../components/Logo'
import Container from '../../components/Container'
import Span from '../../elements/Span'

const MAX_SIZE = 16
const MIN_SIZE = 0.6

export default ({ data }) => {
  const { value, name } = data.typeScale

  const fontSizes = [
    1 / (value * 2),
    1 / value,
    1,
    value * 2,
    value * 3,
    value * 4,
    value * 5
  ]
    .filter(f => f <= MAX_SIZE)
    .filter(f => f >= MIN_SIZE)

  return (
    <Layout>
      <Link style={{ padding: 16, display: 'block' }} to="/">
        <Logo />
      </Link>
      <Container>
        <h1
          css={{
            marginBottom: 16,
            paddingLeft: 16,
            paddingRight: 16
          }}
        >
          {name}
        </h1>
        <div>
          <table cellSpacing={0} css={{ width: '100%', tableLayout: 'fixed' }}>
            <tr>
              {fontSizes.map((fontSize, i) => (
                <td
                  key={fontSize}
                  css={{
                    fontFamily: 'system-ui, sans-serif',
                    fontSize: 12,
                    paddingRight: 16,
                    paddingLeft: 16,
                    paddingBottom: 4,
                    paddingTop: 4,
                    textAlign: 'center',
                    verticalAlign: 'baseline',
                    fontWeight: 600,
                    borderBottom: `1px solid ${theme.colors.gray[7]}`,
                    borderTop: `1px solid ${theme.colors.gray[7]}`
                  }}
                >
                  {i}
                </td>
              ))}
            </tr>
            <tr>
              {fontSizes.map(fontSize => (
                <td
                  key={fontSize}
                  css={{
                    fontFamily: 'system-ui, sans-serif',
                    fontSize: fontSize + 'rem',
                    paddingRight: 16,
                    paddingLeft: 16,
                    textAlign: 'center',
                    verticalAlign: 'baseline',
                    fontWeight: 600
                  }}
                >
                  A<Span display={['none', 'inline']}>a</Span>
                </td>
              ))}
            </tr>
            <tr>
              {fontSizes.map(fontSize => (
                <td
                  key={fontSize}
                  css={{
                    borderTop: `1px solid ${theme.colors.gray[7]}`,
                    borderBottom: `1px solid ${theme.colors.gray[7]}`,
                    borderCollapse: 'collapse',
                    fontSize: 12,
                    fontFamily: 'system-ui, sans-serif',
                    paddingRight: 16,
                    paddingLeft: 16,
                    textAlign: 'center',
                    paddingTop: 4,
                    paddingBottom: 4
                  }}
                >
                  {fontSize.toFixed(2) * 16 + 'px'}
                </td>
              ))}
            </tr>
          </table>
          <div css={{ marginTop: 64 }}>
            <h3 css={{ paddingRight: 16, paddingLeft: 16, margin: 0 }}>
              Preview
            </h3>
            {fontSizes.map(fontSize => (
              <div css={{ overflowX: 'auto' }}>
                <article
                  key={fontSize}
                  css={{
                    borderBottom: `1px solid ${theme.colors.gray[7]}`,
                    fontSize: fontSize + 'rem',
                    fontFamily: 'system-ui, sans-serif',
                    paddingRight: 16,
                    paddingLeft: 16,
                    paddingTop: 32,
                    paddingBottom: 32
                  }}
                >
                  <span
                    css={{ fontSize: 14, display: 'block', marginBottom: 16 }}
                  >
                    {fontSize.toFixed(2) * 16 + 'px'}
                  </span>
                  <p css={{ fontWeight: 800, margin: 0 }}>
                    ABCDEFGHIJKLMNOPQRSTUVWXYZ
                  </p>
                  <p css={{ fontWeight: 600, margin: 0 }}>
                    abcdefghijklmnopqrstuvwxyz
                  </p>
                  <p css={{ whiteSpace: 'nowrap', fontWeight: 400, margin: 0 }}>
                    1234567890!@#$%^&*()
                  </p>
                </article>
              </div>
            ))}
          </div>
        </div>
        <div css={{ marginTop: 64, marginBottom: 64 }}>
          <Link
            css={{
              fontSize: 24,
              color: 'black'
            }}
            to="/explore/type-scales"
          >
            See all type scales →
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TypeScale($name: String) {
    typeScale: typeScalesJson(name: { eq: $name }) {
      name
      value
    }
  }
`
